<template>
<!--  <div class="row m-0 mt-md-3 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">新闻资讯</div>
      <div class="list-group p-0">
        <a href="javascript:void(0);" @click="getNews(1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">新闻资讯</a>
        <a href="javascript:void(0);" @click="getNewsByModular(1,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心新闻</a>
        <a href="javascript:void(0);" @click="getNewsByModular(2,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">学术交流</a>
        <a href="javascript:void(0);" @click="getNewsByModular(3,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">创作研究</a>
        <a href="javascript:void(0);" @click="getNewsByModular(4,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">人才队伍建设</a>
        <a href="javascript:void(0);" @click="getNewsByModular(5,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">业界动态</a>
        <a href="javascript:void(0);" @click="getNewsByModular(6,1)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">通知公告</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp bg-white p-0 w-100">
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a class="" href="/">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">新闻资讯</li>
          </ol>
        </nav>
        <table class="w-100 table-sm table-hover small">
          <tbody>
          <tr class="text-left" v-for="(newList,index) in newsList" v-bind:key="index">
            <a href="javascript:(0);" class="text-dark" @click="content(newList.newsId)">
              <td class="w-75"><div class="float-left pl-4 small">{{ newList.newsTitle }}</div></td>
              <td class="w-25 text-right">
                <div class="float-right small align-self-center pr-5">{{ newList.updateTime }}</div>
              </td>
            </a>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="container fixed-bottom">
        <ul class="pagination pagination-sm m-0">
          <li class="small align-self-center pr-3">
            <span>共</span><span class="pl-2 pr-2">{{ page.totalSize }}</span><span>条信息</span>
            <span class="pl-4">每页</span><span class="pl-2 pr-2">12</span><span>条信息</span>
          </li>
          <li class="page-item"><a class="page-link" href="#" @click="getNews(1)">首页</a></li>
          <li class="page-item" v-for="n in page.totalPage" :class="{active:n == page.pageNo}" :key="n">
            <a class="page-link" href="javascript:void(0);" @click="getNews(n)">{{ n }}</a>
          </li>
          <li class="page-item"><a class="page-link" href="#"  @click="getNews(page.pageNo + 1)">下一页</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "newlist",
  data() {
    return {
      newsList: '',
      page: ''
    }
  },
  mounted() {
    // console.log(this.$route.query.modularId)
    // console.log(this.$route.query.modularId === undefined)
    if (this.$route.query.modularId === undefined) {
      this.getNews(1)
    }
    this.getNewsByModular(this.$route.query.modularId, 1)
  },
  methods: {
    getNews(pageNo) {
      this.$http({
        method: "get",
        url: "/api/cmsReception/getNewsList/" + pageNo
      }).then((res) => {
        var news = res.data
        this.newsList = news.data
        this.page = news.page
      })
    },
    getNewsByModular(modular, pageNo) {
      if (modular == null || modular == '') return
      this.$http({
        method: "get",
        url: "/api/cmsReception/getNewsListByModular/" + modular + "/" + pageNo
      }).then((res) => {
        var news = res.data
        this.newsList = news.data
        this.page = news.page
      })
    },
    content(newId) {
      this.$router.push({
        path: '/cmsReception.html/',
        query: {newId: newId}
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>